import React from 'react'
import UserProfile from '../UserProfile'
import basicStyles from '../../styles.module.css'
import styles from './styles.module.css'

const AuthSection = ({
  isAuthenticated,
  user,
  toggleSignIn,
  toggleSignOut,
  redirectPathAfterSignIn,
  redirectPathAfterSignOut
}) => {
  return (
    <div className={styles['auth-section']}>
      {!isAuthenticated ? (
        <button
          type='button'
          className={`${basicStyles['transparent-btn']} ${basicStyles['sign-in-and-out-btn']} ${basicStyles['ui-button']}`}
          onClick={() => toggleSignIn(redirectPathAfterSignIn)}
        >
          Sign in
        </button>
      ) : (
        <UserProfile
          user={user}
          redirectPathAfterSignOut={redirectPathAfterSignOut}
          toggleSignOut={toggleSignOut}
        />
      )}
    </div>
  )
}

export default AuthSection
