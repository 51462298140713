import React, { Fragment } from 'react'
import basicStyles from '../../styles.module.css'
import styles from './styles.module.css'

const UserProfile = ({ user, toggleSignOut, redirectPathAfterSignOut }) => (
  <>
    {user && (
      <div className={styles['user-info']}>
        {user.picture && (
          <img
            className={styles.picture}
            src={user.picture}
            alt='User avatar'
          />
        )}

        <span className={styles.username}>{user.name}</span>
      </div>
    )}

    <button
      type='button'
      className={`${basicStyles['transparent-btn']} ${basicStyles['sign-in-and-out-btn']} ${basicStyles['ui-button']}`}
      onClick={() => toggleSignOut(redirectPathAfterSignOut)}
    >
      Sign out
    </button>
  </>
)

export default UserProfile
