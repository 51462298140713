import React, { useState, Fragment } from 'react'
import 'primeicons/primeicons.css'
import Sidebar from '../Sidebar'
import { ReactComponent as Logo } from '../../images/lion.svg'
import TabNav from '../TabNav'
import AuthSection from '../AuthSection'
import basicStyles from '../../styles.module.css'
import styles from './styles.module.css'

const Header = ({
  isAuthenticated,
  sidebarLinksEndpoint,
  title,
  user,
  redirectPathAfterSignIn,
  redirectPathAfterSignOut,
  toggleSignIn,
  toggleSignOut,
  tabs,
  children
}) => {
  const [isSidebarOpen, setSidebarOpenClosed] = useState(false)

  const onOpenSidebar = () => {
    setSidebarOpenClosed(true)
  }

  const onCloseSidebar = () => {
    setSidebarOpenClosed(false)
  }

  return (
    <div
      className={`${styles.wrapper} ${
        isSidebarOpen && styles['mobile-wrapper']
      }`}
    >
      {isAuthenticated && (
        <Sidebar
          onCloseSidebar={onCloseSidebar}
          isSidebarOpen={isSidebarOpen}
          isAuthenticated={isAuthenticated}
          sidebarLinksEndpoint={sidebarLinksEndpoint}
          title={title}
          user={user}
          redirectPathAfterSignOut={redirectPathAfterSignOut}
          toggleSignOut={toggleSignOut}
          tabs={tabs}
        />
      )}
      {isSidebarOpen ? <div className={styles['blur-container']} /> : <></>}
      <div
        className={`${styles['main-content']} ${
          isSidebarOpen ? styles['main-content-sidebar-open'] : styles['']
        }`}
      >
        <div className={`${basicStyles['sticky-top']} ${styles.header}`}>
          <div className={styles.container}>
            {isAuthenticated && (
              <div>
                {isSidebarOpen ? (
                  <></>
                ) : (
                  <button
                    className={`${basicStyles['transparent-btn']} ${styles['menu-btn']} ${basicStyles['ui-button']}`}
                    onClick={onOpenSidebar}
                  >
                    <i className='pi pi-bars' />
                  </button>
                )}
              </div>
            )}

            <span className={styles['full-logo']}>
              {isSidebarOpen ? (
                <span className={`${styles['logo-text']} ${styles['neg-ml']}`}>
                  {title}
                </span>
              ) : (
                <>
                  <div className={styles.logo}>
                    <Logo height={40} />
                  </div>

                  <span className={styles['logo-text']}>Leobit {title}</span>
                </>
              )}
            </span>

            {isAuthenticated && tabs && (
              <div className={styles['tabs-container-header']}>
                <TabNav tabs={tabs} />
              </div>
            )}

            <div className={styles['user-container-header']}>
              <AuthSection
                isAuthenticated={isAuthenticated}
                user={user}
                toggleSignIn={toggleSignIn}
                toggleSignOut={toggleSignOut}
                redirectPathAfterSignIn={redirectPathAfterSignIn}
                redirectPathAfterSignOut={redirectPathAfterSignOut}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Header
