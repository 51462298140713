import React, { useEffect, useState } from 'react'
import 'primeicons/primeicons.css'
import basicStyles from '../../styles.module.css'
import { ReactComponent as Logo } from '../../images/lion.svg'
import TabNav from '../TabNav'
import AuthSection from '../AuthSection'
import styles from './styles.module.css'

const Sidebar = ({
  sidebarLinksEndpoint,
  isSidebarOpen,
  isAuthenticated,
  onCloseSidebar,
  title,
  tabs,
  user,
  redirectPathAfterSignIn,
  redirectPathAfterSignOut,
  toggleSignIn,
  toggleSignOut
}) => {
  const [navigation, setNavigation] = useState([])

  useEffect(() => {
    fetch(sidebarLinksEndpoint, {
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((data) => setNavigation(data))
      .catch((error) => console.log(error))
  }, [])

  return (
    <div
      className={`${styles['sidebar-container']} ${
        isSidebarOpen ? styles['sidebar-container-open'] : styles['']
      }`}
    >
      <div className={styles.sidebar}>
        <div className={styles['sidebar-top']}>
          <div className={styles.logo}>
            <Logo height={40} />
          </div>

          <span className={styles['logo-text']}> Leobit </span>

          <button
            className={`${styles['transparent-btn']} ${styles['back-btn']} ${basicStyles['ui-button']}`}
            onClick={onCloseSidebar}
          >
            <i className='pi pi-angle-double-left' />
          </button>
        </div>

        {navigation.map((navigationInfo) => (
          <div key={navigationInfo.name}>
            <a
              className={`${styles['nav-btn']} ${
                title === navigationInfo.name && styles.active
              }`}
              href={navigationInfo.url}
            >
              <object
                type='image/svg+xml'
                data={navigationInfo.logoUrl}
                className={styles['nav-icon']}
              />

              <object
                type='image/svg+xml'
                data={navigationInfo.logoActiveUrl}
                className={`${styles['nav-icon']} ${styles['nav-icon-active']}`}
              />

              <span>{navigationInfo.name}</span>
            </a>

            {isAuthenticated && tabs && (
              <div className={styles['tabs-container-sidebar']}>
                <div
                  className={`${
                    title !== navigationInfo.name && styles['tabs-non-active']
                  }`}
                >
                  <TabNav tabs={tabs} />
                </div>
              </div>
            )}
          </div>
        ))}

        <div className={styles['user-container-sidebar']}>
          <AuthSection
            isAuthenticated={isAuthenticated}
            user={user}
            toggleSignIn={toggleSignIn}
            toggleSignOut={toggleSignOut}
            redirectPathAfterSignIn={redirectPathAfterSignIn}
            redirectPathAfterSignOut={redirectPathAfterSignOut}
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
