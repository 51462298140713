import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'

const TabNav = ({ tabs }) => {
  const currentPathname = window.location.pathname
  const [selectedTab, setSelectedTab] = useState(currentPathname)

  const onSelectedTab = (route, command) => {
    setSelectedTab(route)
    command()
  }

  useEffect(() => {
    const currentTab = tabs.find((tab) => tab.route === currentPathname)

    if (currentTab) {
      setSelectedTab(currentTab.route)
    }

    if (currentPathname === '/') {
      setSelectedTab(tabs[0].route)
    }
  }, [currentPathname, tabs])

  return (
    <div className={styles.tab}>
      {tabs.map((tab) => (
        <div
          onClick={() => onSelectedTab(tab.route, tab.command)}
          key={tab.id}
          className={`${styles['tab-links']} ${
            selectedTab === tab.route && styles['tab-selected']
          }`}
        >
          <span>{tab.lable}</span>
        </div>
      ))}
    </div>
  )
}

export default TabNav
